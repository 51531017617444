import React, { createContext, useState } from "react";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
  const cro_apiUrl = "https://testnet-croeseid-4.crypto.org:1317";
  const cronos_nodeUrl = "https://evm-dev-t3.cronos.org/";
  const chainId = "338";
  const ptCRO_ADDRESS = "0x1Df21F91Bf57C5dA3F287835Fe885f7584f5c16A";
  const ptCRO_ABI = require("./abis/ptCRO_abi.json");
  const ytCRO_ADDRESS = "0xa71fF1e273157be3D79fEE50AaBdb333eF4508DF";
  const ytCRO_ABI = require("./abis/ytCRO_abi.json");
  const Factory_ADDRESS = "0x4d5cF7082acbEDf59895dEff49Ae46E546A3195f";
  const Factory_ABI = require("./abis/Factory_abi.json");
  const MasterBaker_ADDRESS = "0x2372e33658890d40311aE54DB6b18678F7e30A7c";
  const MasterBaker_ABI = require("./abis/MasterBaker_abi.json");
  const cro_delegator_address = ["tcro145ymnejcx8f7facx7sgjen2yw2wqvcyecvcq28", 
                                 "tcro1t03f8nhn24yf2q4n8jc4j2xa62wl9adc980awf",
                                 "tcro1lyud40q3yznsdrzl3ppdrlvvnnuwga5pf59a6h",
                                 "tcro17z2k0xzhpnnst4xqp3hslfnc6w6dc8r5hzfqxa",
                                 "tcro1msdedrz2897w5qxd26gelqx5mg4vdjmd0r2dlf" ];
  const ptCRO_WCRO_address = "0x9a0941cD0E0b6F567A55aFBB7D26abBc1f3DE1B9";
  const ytCRO_WCRO_address = "0x090B4A710d1067fb5C18A290b2B16518fFc166aE";
  const PAN_WCRO_address   = "0xf9C03b2da238C5b7859212c03EE0BA9cF866b059";
  const WCRO_address       = "0xC68495cB4e43d220720215624E83e5dda1a5E270";
  const PAN_address        = "0xC9fC9F4A9861DeA2042b2Ce8e29384108f56C8Bd";
// SWAP 
  const uniswapRouterAddress = "0x9ee5cce1e6bd7a567fea0cb2bcd9696df654b7a1";
  const [slippageTolerance, setSlippageTolerance] = useState(0.005); //0.5%
  const [tradeDeadLine, setTradeDeadLine] = useState(20);            //20mins
  const [multiHops, setMultiHops] = useState(true);                  //multiHops on
  const [tradeType, setTradeType] = useState(0); //0: "EXACT_INPUT"; 1: "EXACT_OUTPUT"
  const [swapCoin1, setSwapCoin1] = useState({
    name: "",
    address: "",
    symbol: "",
    decimals: 0,
    icon: "",
  });
  const [swapCoin2, setSwapCoin2] = useState({
    name: "",
    address: "",
    symbol: "",
    decimals: 0,
    icon: "",
  });
  const [swapPath, setSwapPath] = useState([]);
//
// Liquidity
const [liquidityCoin1, setLiquidityCoin1] = useState({
  name: "",
  address: "",
  symbol: "",
  decimals: 0,
  icon: "",
});
const [liquidityCoin2, setLiquidityCoin2] = useState({
  name: "",
  address: "",
  symbol: "",
  decimals: 0,
  icon: "",
});
//
  const PAN_ABI            = require("./abis/PAN_abi.json");

  const [stakedCRO, setStakedCRO]       = useState(0);
  const [unstakingCRO, setUnstakingCRO] = useState(0);
  const [web3Provider, setWeb3Provider] = useState(null);
  const [ptCROContract, setptCROContract] = useState(null);
  const [balanceCRO, setBalanceCRO] = useState(0);
  const [balanceWCRO, setBalanceWCRO] = useState(0);
  const [balancePAN, setBalancePAN] = useState(0);
  const [balanceptCRO, setBalanceptCRO] = useState(0);
  const [balanceytCRO, setBalanceytCRO] = useState(0);
  const [pendingRewards, setPendingRewards] = useState(0);
  const [amount, setAmount] = useState("");
  const [flexibleCRO, setFlexibleCRO] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedSwapTab, setSelectedSwapTab] = useState(0);
  const [walletAddress, setWalletAddress] = useState("");
  const [claimAddress, setClaimAddress] = useState("");
  const [blockNumber, setBlockNumber] = useState(0);
  const [color, setColor] = useState('rgb(55, 160, 105)');
  const [color2, setColor2] = useState('rgb(255, 255, 255)');
  const [aprCRO, setAprCRO] = useState(0);
  const [aprCronos, setAprCronos] = useState(0);
  const [panHodlLevel, setPanHodlLevel] = useState(0);
  const [holdDiscount, setHoldDiscount] = useState(0);
  const [isWalletOpen, setIsWalletOpen] = useState(false);

  const [selectedCoin, setSelectedCoin] = useState({
    name: "",
    symbol: "",
    icon: "",
  });

  const [ytTotalSupply, setYtTotalSupply]    = useState(0);
  const [delegationRaio, setDelegationRatio] = useState(0);
  const [utilization, setUtilization]        = useState(0);
  const [price_ptCRO, setPricePTCRO]         = useState(0);
  const [price_ytCRO, setPriceYTCRO]         = useState(0);
  const [price_PAN,   setPricePAN]           = useState(0);



  const contextValue = {
    ptCRO_ADDRESS,
    ptCRO_ABI,
    ytCRO_ADDRESS,
    ytCRO_ABI,
    Factory_ADDRESS,
    Factory_ABI,
    MasterBaker_ADDRESS,
    MasterBaker_ABI,
    cro_apiUrl,
    cro_delegator_address,
    cronos_nodeUrl,
    chainId,
    ptCRO_WCRO_address,
    ytCRO_WCRO_address,
    PAN_WCRO_address,
    WCRO_address,
    PAN_address,
    uniswapRouterAddress,
    PAN_ABI,
    web3Provider,
    setWeb3Provider,
    ptCROContract,
    setptCROContract,
    balanceCRO,
    setBalanceCRO,
    balanceWCRO,
    setBalanceWCRO,
    balanceptCRO,
    setBalanceptCRO,
    balanceytCRO,
    setBalanceytCRO,
    balancePAN,
    setBalancePAN,
    pendingRewards,
    setPendingRewards,
    amount,
    setAmount,
    flexibleCRO,
    setFlexibleCRO,
    isLoading,
    setIsLoading,
    selectedTab,
    setSelectedTab,
    selectedSwapTab,
    setSelectedSwapTab,
    walletAddress,
    setWalletAddress,
    claimAddress,
    setClaimAddress,
    selectedCoin,
    setSelectedCoin,
    ytTotalSupply,
    setYtTotalSupply,
    delegationRaio,
    setDelegationRatio,
    utilization,
    setUtilization,
    price_ptCRO,
    setPricePTCRO,
    price_ytCRO,
    setPriceYTCRO,
    price_PAN,
    setPricePAN,
    blockNumber,
    setBlockNumber,
    color,
    setColor,
    color2,
    setColor2,
    aprCRO,
    setAprCRO,
    aprCronos,
    setAprCronos,
    panHodlLevel,
    setPanHodlLevel,
    holdDiscount,
    setHoldDiscount,
    stakedCRO,
    setStakedCRO,
    unstakingCRO,
    setUnstakingCRO,
    isWalletOpen,
    setIsWalletOpen,
    slippageTolerance, setSlippageTolerance,
    tradeDeadLine, setTradeDeadLine,
    multiHops, setMultiHops,
    tradeType, setTradeType,
    swapCoin1, setSwapCoin1,
    swapCoin2, setSwapCoin2,
    swapPath, setSwapPath,
    liquidityCoin1, setLiquidityCoin1,
    liquidityCoin2, setLiquidityCoin2,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
