import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";
  import { isBrowser, isMobile} from "react-device-detect";

  import { useWeb3 } from "../hooks/useWeb3";
  import { AppContext } from "../context";


  export default function AccountModal({isOpen, onClose}) {
    const [search, setSearch] = useState("");
    const {
      selectedCoin,
      setSelectedCoin,
    } = useContext(AppContext);

    const { handleCryptoOrgExplorer, handleExplorer } = useWeb3();

    const AccountList = [
      {
        name: "Account1",
        address: "tcro1t03f8nhn24yf2q4n8jc4j2xa62wl9adc980awf",
      },
      {
        name: "Account2",
        address: "tcro1lyud40q3yznsdrzl3ppdrlvvnnuwga5pf59a6h",
      },
      {
        name: "Account3",
        address: "tcro17z2k0xzhpnnst4xqp3hslfnc6w6dc8r5hzfqxa",
      },
      {
        name: "Account4",
        address: "tcro1msdedrz2897w5qxd26gelqx5mg4vdjmd0r2dlf",
      },
    ];

    

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Stake and unstake account address
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>

            <div>
              <Table variant='unstyle' mt="0.3rem">
                <Tbody>
                  {AccountList
                    .filter((val) => {
                      return val.address.toLowerCase().includes(search.toLowerCase());
                    })
                    .map((val) => {
                      return (
                          <Tr key={val.name}
                              style={{backgroundColor: ""}}
                              _hover={{backgroundColor: "orange.200", cursor: "pointer" }}
                              onClick={() => handleCryptoOrgExplorer( ("/account/" + val.address) )}>
                            <Td>
                                <Flex alignItems="center" justifyContent="left">
                                <Box>
                                <Text fontSize={(isMobile) ? '18px' : '20px'} fontWeight="bold" mb="0.3rem">{val.name}</Text>
                                <Text fontSize={(isMobile) ? '14px' : '16px'} color="#848482" fontWeight="bold">{val.address}</Text>
                                </Box>
                                </Flex>
                            </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </div>
          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  