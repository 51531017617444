import React, { useContext } from "react";
import { Flex, Box } from "@chakra-ui/react";
import { AppContext } from "../context";

export default function SwitchTab({ tabs, onChange }) {
  const {
    selectedTab,
    setSelectedTab,
  } = useContext(AppContext);

  const handleTabChange = (index) => {
    setSelectedTab(index);
    onChange(index);
    //console.log(selectedTab);
  };

  const tabWidth = React.useMemo(() => {
    const maxWidth = Math.max(...tabs.map((tab) => tab.length));
    return `${maxWidth * 15}px`;
  }, [tabs]);

  return (
    <Flex w="100%" justifyContent="center" justify="space-between">
      {tabs.map((tab, index) => (
        <Box
          key={tab}
          bg={selectedTab === index ? "orange" : "orange.200"}
          border="2px"
          borderColor={selectedTab === index ? "white" : "orange.200"}
          color={selectedTab === index ? "white" : "#551a8b"}
          fontWeight={selectedTab === index ? "bold" : "bold"}
          p={2}
          ml={index !== 0 ? "0.3rem" : 0}
          borderRadius="1.35rem"
          cursor="pointer"
          transition="background-color 0.3s"
          _hover={{ bg: selectedTab === index ? "orange" : "orange.200" }}
          onClick={() => handleTabChange(index)}
          width={tabWidth}
        >
          {tab}
        </Box>
      ))}
    </Flex>
  );
}