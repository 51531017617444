import React, { useState, useContext, useEffect } from "react";
import { ethers } from "ethers";
import { Flex, Button, FormControl, Box, FormLabel, Heading, Input, Text, useDisclosure, Image } from "@chakra-ui/react";
import { SettingsIcon, ArrowDownIcon } from '@chakra-ui/icons';
import { isBrowser, isMobile} from "react-device-detect";
import { isIOS, isAndroid } from "react-device-detect";
import { Line } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import { useWeb3 } from "./hooks/useWeb3";
import { useIcon } from "./hooks/useIcon";
import { useFactory } from "./hooks/useFactory";

import MintButton from "./components/MintButton";
import TokenSelect from "./components/TokenSelect";
import TokenSelect2 from "./components/TokenSelect2";
import TokenModal from "./components/TokenModal";
import InteractiveLineChart from './components/InteractiveLineChart';

import logo_cronos from "./assets/cronos_light_bg.png";
import logo_ptcro from "./assets/ptCRO.png";
import logo_ytcro from "./assets/ytCRO.png";

import { AppContext } from "./context";

function Burn() {
    const {
        web3Provider,
        amount,
        setAmount,
        isLoading,
        selectedCoin,
        utilization,
        blockNumber,
        color,
        color2,
        panHodlLevel,
        holdDiscount
      } = useContext(AppContext);

      const { isOpen, onOpen, onClose } = useDisclosure();

      const { handleExplorer } = useWeb3();
      const { CircleIcon } = useIcon();
      const { burn_ptCRO } = useFactory();

      const chartData = {
        labels: [  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',
                  '10','11','12','13','14','15','16','17','18','19',
                  '20','21','22','23','24','25','26','27','28','29',
                  '30','31','32','33','34','35','36','37','38','39',
                  '40','41','42','43','44','45','46','47','48','49',
                  '50','51','52','53','54','55','56','57','58','59',
                  '60','61','62','63','64','65','66','67','68','69',
                  '70','71','72','73','74','75','76','77','78','79',
                  '80','81','82','83','84','85','86','87','88','89',
                  '90','91','92','93','94','95','96','97','98','99',
                 '100'                                              ],
        datasets: [
          {
            label: 'Interest Rate Model',
            data: [ 0.500,  0.525,  0.555,  0.575,  0.600,  0.625,  0.650,  0.675,  0.700,  0.725,
                    0.750,  0.775,  0.800,  0.825,  0.850,  0.875,  0.900,  0.925,  0.950,  0.975,
                    1.000,  1.025,  1.050,  1.075,  1.100,  1.125,  1.150,  1.175,  1.200,  1.225,
                    1.250,  1.275,  1.300,  1.325,  1.350,  1.375,  1.400,  1.425,  1.450,  1.475,
                    1.500,  1.525,  1.550,  1.575,  1.600,  1.625,  1.650,  1.675,  1.700,  1.725,
                    1.750,  1.775,  1.800,  1.825,  1.850,  1.875,  1.900,  1.925,  1.950,  1.975,
                    2.000,  2.025,  2.050,  2.075,  2.100,  2.125,  2.625,  3.125,  3.625,  4.125,
                    4.625,  5.125,  5.625,  6.125,  6.625,  7.125,  7.625,  8.125,  8.625,  9.125,
                    9.625, 10.125, 10.625, 11.125, 11.625, 12.125, 12.625, 13.125, 13.625, 14.125,
                   14.625, 15.125, 15.625, 16.125, 16.625, 17.125, 17.625, 18.125, 18.625, 19.125,
                   19.625,                                                                        ],
            borderColor: 'rgb(0, 123, 255)', // Blue
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgb(0, 123, 255)',
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointHitRadius: 30,
            pointRadius: 0,
            borderWidth: 3,
          },
          {
            label: 'U Rate Line',
            data: [25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25, 25, 25, 25, 25, 25, 25, 25, 25, 25,
                   25,                                    ],
            borderColor: 'rgb(220, 53, 69)', // Red
            pointBorderColor: 'transparent',
            pointBackgroundColor: 'transparent',
            pointHoverBackgroundColor: 'rgb(220, 53, 69)',
            pointHoverRadius: 5,
            pointHoverBorderWidth: 2,
            pointHitRadius: 30,
            pointRadius: 0,
            borderWidth: 3,
          },
        ],
      };

      return (
        <Box
        className="container"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                           : "92.3vh"}   
        overflow={(isMobile) ? "scroll" : "hidden" }
        >
        <TokenModal isOpen={isOpen} onClose={onClose} />

        <Box>
          <Box
          w={(isMobile) ? "95vw" : "33rem"}
          mx="auto"
          position="relative"
          top={(isMobile) ? "50px" : "120px" }
          boxShadow="rgb(0 0 0 / 8%) 0rem 0.37rem 0.62rem"
          borderRadius="1.25rem">
      
            <Flex
              alignItems="center"
              p="1rem 1rem 1rem"
              bg="white"
              color="rgb(86, 90, 105)"
              justifyContent="space-between"
              borderRadius="1.25rem 1.25rem 0 0">
              <Text
                color="black"
                fontWeight="bold">
                Burn ptCRO + ytCRO
              </Text>
              <SettingsIcon
                fontSize="1.25rem"
                cursor="pointer"
                _hover={{ color: "rgb(128,128,128)" }}
              />
            </Flex>
      
            <Box
              p="0rem 0.5rem 0.5rem 0.5rem"
              bg="white"
              borderRadius="0 0 0rem 0rem"
              >
              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p="1rem 1rem 1rem"
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                height="75px"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}>
                <Box>
                  <Text
                    width="70px"
                    color="black"
                    fontWeight="bold">
                    Amount:
                  </Text>
                </Box>
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  bg="white"
                  p="0.18rem"
                  borderRadius="1.25rem"
                  pos="absolute"
                  top="7.8rem"
                  left="50%"
                  transform="translateX(-50%)"
                  w="2rem">
                  <ArrowDownIcon
                  bg="rgb(247, 248, 250)"
                  color="rgb(128,128,128)"
                  h="1.5rem" width="1.62rem"
                  borderRadius="1.25rem"
                  />
                </Flex>    
                <Box>
                  <Input
                    placeholder="0.0"
                    fontWeight="500"
                    fontSize="1.4rem"
                    width="100%"
                    size="19rem"
                    textAlign="right"
                    bg="rgb(247, 248, 250)"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    type="text"
                    inputMode="decimal"
                    color="black"
                    value={amount}
                    style={{ boxShadow: "none" }}
                    onChange={(e) =>
                      setAmount(
                        e.target.value.replace(/[^0-9.]/g, "").replace(/(\..*)\./g, "$1")
                      )
                    }
                  />
                </Box>
                <Box ml="0.5rem">
                  <TokenSelect2 value={"+"} image={logo_ptcro} image2={logo_ytcro}/>
                </Box>
              </Flex>

              <Flex
                alignItems="center"
                justifyContent="space-between"
                bg="rgb(247, 248, 250)"
                p="1rem 1rem 1rem"
                mt="0.25rem"
                borderRadius="1.25rem" border="0.06rem solid rgb(237, 238, 242)"
                height="75px"
                _hover={{ border: "0.06rem solid rgb(211,211,211)" }}
                >
                <Box>
                  <Text
                    width="70px"
                    color="black"
                    fontWeight="bold">
                    To:
                  </Text>
                </Box>
                <Box>
                  <Input
                    placeholder="0.0"
                    fontWeight="500"
                    fontSize="1.4rem"
                    width="100%"
                    size="19rem"
                    textAlign="right"
                    bg="rgb(247, 248, 250)"
                    outline="none"
                    border="none"
                    focusBorderColor="none"
                    borderRadius="0.25rem"
                    type="text"
                    inputMode="decimal"
                    color="black"
                    value={amount}
                    isDisabled
                    style={{ boxShadow: "none", cursor: "auto" }}
                  />
                </Box>
                <Box ml="0.5rem">
                <TokenSelect openTokenModal={onOpen} value={selectedCoin.symbol} image={selectedCoin.icon}/>
                </Box>
              </Flex>
            </Box>

            <Box p="0.5rem" bg="white" borderRadius="0 0 0rem 0rem">
              <Flex>
                <Text mt="0.5rem" mr="0.2rem" fontWeight="bold">Fee Rate Model: </Text>
                <Text mt="0.5rem" fontWeight="bold" color="rgb(220, 53, 69)">utilization</Text>
                <Text mt="0.5rem" mr="0.2rem" ml="0.2rem" fontWeight="bold">&</Text>
                <Text mt="0.5rem" fontWeight="bold" color="rgb(0, 123, 255)">fee</Text>
              </Flex>
              <InteractiveLineChart data={chartData} utilization={utilization}/>
            </Box>

            <Box p="0.5rem" bg="white" borderRadius="0 0 0rem 0rem">
              <Flex>
                <Text mt="0.5rem" mr="0.2rem" fontWeight="bold">PAN Hodl: </Text>
                <Text mt="0.5rem" fontWeight="bold" color="gray"> level {panHodlLevel}&nbsp;</Text>
                <Text mt="0.5rem" fontWeight="bold" color="green.600">({holdDiscount}% fee discount)</Text>
              </Flex>
            </Box>

            <MintButton onClick={burn_ptCRO} isLoading={isLoading} text="Burn" allowed={true} />

          </Box>

          {(isMobile) && (
            <Flex w="30px" h="5rem">
            </Flex>
            )}

        </Box>

          <Flex bottom="0" justify="space-between" alignItems="center" width="100vw" mb={(isAndroid) ? "1.6rem" : "0.2rem"}>

            <Flex mr="16px" w="90px">
            </Flex>

            <Flex alignItems="center">
              <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
              <Box width="85px" height="30px">
                <Image src={logo_cronos} fit="contain"/>
              </Box>
            </Flex>

            <Flex alignItems="center">
              {web3Provider && (
              <Flex alignItems="center">
              <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
              <CircleIcon boxSize={3} color={color} mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
              {!web3Provider && (
              <Flex alignItems="center">
              <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
              <CircleIcon boxSize={3} color="transparent" mr={(isMobile) ? '0px' : '16px'}/>
              </Flex>
              )}
            </Flex>

          </Flex>
        
        </Box>
      );
  
}

export default Burn;
