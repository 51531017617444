import { extendTheme } from "@chakra-ui/react";
import { isBrowser, isMobile} from "react-device-detect";
//import "@fontsource/inter";

//overflow: (isMobile ? 'scroll' : 'hidden'),

export const theme = extendTheme({
  fonts: {
    heading: "Arial",
    body: "Arial",
  },
  styles: {
    global: {
      "html, body": {
        backgroundImage: 'linear-gradient(to bottom right, #FFFFFF, #FFAE42)',
        minHeight: '100vh',
        overflow: 'hidden',
      },
    },
  },
});
