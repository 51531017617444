import { useContext } from "react";
import { Button, Box, Spinner} from "@chakra-ui/react";
import { AppContext } from "../context";
import { useWeb3 } from "../hooks/useWeb3";

export default function MintButtonSmall({ onClick, isLoading, text, stakeChecked, allowed, amount }) {
  const {
    web3Provider,
    setIsWalletOpen
  } = useContext(AppContext);

  const { connectWeb3 } = useWeb3();

  return web3Provider ? (
    <Box mt="0.5rem" bg="rgb(246,246,246)" borderRadius="0 0 0.65rem 0.65rem">
      <Button
        bg={stakeChecked ? (allowed ? "rgb(220,220,220)" : "rgb(255,0,122)") : "rgb(220,220,220)"}
        width="100%"
        p="0.5rem"
        borderRadius="1rem"
        color={isLoading || amount===""   ? "#FFFFFF"                  : (stakeChecked ? (allowed ? "#007AFF" : "#FFFFFF") : "rgb(205,44,44)" ) }
        _hover={isLoading || amount===""  ? { bg: "rgb(220,220,220)" } : (stakeChecked ? (allowed ? {bg:"rgb(160,190,190)"} : {bg:"rgb(220,40,112)"}) : {bg:"rgb(160,190,190)"})}
        onClick={isLoading || amount==="" ?  null                      : onClick}
        disabled={isLoading || amount===""}
        rightIcon={isLoading ? <Spinner size="sm" speed='0.8s'/> : null}
      >
        {text}
      </Button>
    </Box>
  ) : (
    <Box p="0.5rem" bg="rgb(246,246,246)" borderRadius="0 0 0.65rem 0.65rem">
      <Button
        color="white"
        bg="orange"
        width="100%"
        p="0.5rem"
        borderRadius="1rem"
        _hover={{ bg: "orange.400" }}
        onClick={ () => {setIsWalletOpen(true)} }
      >
        Connect Wallet
      </Button>
    </Box>
  );
}
