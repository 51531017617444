import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";

  import { useWeb3 } from "../hooks/useWeb3";
  import { AppContext } from "../context";

  import logo_MetaMask from "../assets/MetaMask_Fox.png";  
  import logo_CryptoDeFiWallet from "../assets/DeFiWallet.png";  

  export default function WalletModal({isOpen, onClose}) {
    const [search, setSearch] = useState("");
    const {
      selectedCoin,
      setSelectedCoin,
    } = useContext(AppContext);

    const { connectWeb3, connectWeb3_CronosDeFi } = useWeb3();

    const WalletList = [
      {
        name: "MetaMast",
        icon: logo_MetaMask,
        connect: connectWeb3,

      },
      {
        name: "Crypto.com DeFi Wallet",
        icon: logo_CryptoDeFiWallet,
        connect: connectWeb3_CronosDeFi,
      },
    ];

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Connect wallet
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>

            <div>
              <Table variant='unstyle' mt="0.3rem">
                <Tbody>
                  {WalletList
                    .map((val) => {
                      return (
                          <Tr key={val.name}
                              style={{backgroundColor: ""}}
                              _hover={{backgroundColor: "orange.200", cursor: "pointer" }}
                              onClick={() => { val.connect(); onClose(); }}>
                            <Td>
                                <Flex alignItems="center" justifyContent="left">
                                <Image src={val.icon} alt="logo" width="40px" borderRadius="1.25rem" mr="1rem"/>
                                <Box>
                                <Text fontSize="20px" fontWeight="bold" p="0.3rem">{val.name}</Text>
                                </Box>
                                </Flex>
                            </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </div>
          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.100"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  