import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";

  import { AppContext } from "../context";

  import coinList from "../coinList.json";
  import { logoMapping } from '../logoMapping';

  export default function SwapModal({isOpen, onClose, tokenNumber}) {
    const [search, setSearch] = useState("");
    const {
      swapCoin1,
      setSwapCoin1,
      swapCoin2,
      setSwapCoin2,
    } = useContext(AppContext);

    coinList.forEach((coin) => {
      if (logoMapping[coin.name]) {
        coin.icon = logoMapping[coin.name];
      }
    });

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Select a token
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>
          <Box
            borderRadius="1.25rem"
            border="0.06rem"
            borderStyle="solid"
            borderColor="gray.200"
            bg="rgb(255, 255, 245)"
            p="0.8em">
            <Input
              placeholder="Search token name"
              fontWeight="500"
              fontSize="1.3rem"
              width="100%"
              size="19rem"
              textAlign="left"
              outline="none"
              border="none"
              focusBorderColor="none"
              borderRadius="0.6rem"
              type="text"
              color="black"
              p="0.3rem"
              style={{ boxShadow: "none" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
              }}
              />
          </Box>

            <div>
              <Table variant='unstyle' mt="0.3rem">
                <Tbody>
                  {coinList
                    .filter((val) => {
                      // Filter based on the search input
                      const includeBasedOnSearch = val.name.toLowerCase().includes(search.toLowerCase());

                      // Exclude specific tokens based on tokenNumber
                      const excludeTokenA       = (tokenNumber === 1 && val.name === swapCoin2.name);
                      const excludeTokenB       = (tokenNumber === 2 && val.name === swapCoin1.name);
                      const excludeTokenForCRO  = (tokenNumber === 2 && swapCoin1.name === "Cronos"         && val.name !== "Wrapped Cronos");
                      const excludeTokenForCRO2 = (tokenNumber === 2 && swapCoin1.name !== "Wrapped Cronos" && val.name === "Cronos"        );

                      return includeBasedOnSearch && !excludeTokenA && !excludeTokenB && !excludeTokenForCRO && !excludeTokenForCRO2;
                    })
                    .map((val) => {
                      return (
                          <Tr key={val.name}
                              style={{ backgroundColor: ((tokenNumber===1) ? (val.name === swapCoin1.name ? "#F7B45D" :"") : (val.name === swapCoin2.name ? "#F7B45D" :"") )}}
                              _hover={{backgroundColor: "orange.200"}}
                              onClick={function (e) {
                                if (tokenNumber===1) {
                                  setSwapCoin1({name: val.name, address: val.address, symbol: val.symbol, decimals: val.decimals, chainId: val.chainId, icon: val.icon});
                                  if ((val.name !== "Wrapped Cronos") && (swapCoin2.name === "Cronos"))         {  // rule1 for CRO 
                                    setSwapCoin2({name: "", address: "", symbol: "", decimals: "", chainId: "", icon: ""});
                                  }
                                  if ((val.name === "Cronos")         && (swapCoin2.name !== "Wrapped Cronos")) {  // rule2 for CRO 
                                    setSwapCoin2({name: "", address: "", symbol: "", decimals: "", chainId: "", icon: ""});
                                  }
                                }
                                else {
                                  setSwapCoin2({name: val.name, address: val.address, symbol: val.symbol, decimals: val.decimals, chainId: val.chainId, icon: val.icon});
                                }

                                onClose();  
                              }}>
                            <Td>
                                <Flex alignItems="center" justifyContent="left">
                                <Image src={val.icon} alt="logo" width="40px" borderRadius="1.25rem" mr="1rem"/>
                                <Box>
                                <Text fontSize="20px" fontWeight="bold" mb="0.3rem">{val.symbol}</Text>
                                <Text fontSize="16px" color="#848482" fontWeight="bold">{val.name}</Text>
                                </Box>
                                </Flex>
                            </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </div>
          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  