import {Box, Button, Image, Text, Flex} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";

export default function TokenSelect3({image, image2, value}) {
    return (
        <Box
          w="9rem"
          bg="white.50"
          borderRadius="1.25rem"
          boxShadow="rgba(0, 0, 0, 0.075) 0px 6px 10px"
          fontWeight="500"
          _hover={{ bg: "white.50" }}
          color="black">
          <Flex alignItems="center" justifyContent="center">
          <Image boxSize="2rem" alt="Logo" mr="0.25rem" borderRadius="1.25rem" mt="0.3rem" mb="0.3rem" src={image} />
          <Image boxSize="2rem" alt="Logo" mr="0.35rem" borderRadius="1.25rem" mt="0.3rem" mb="0.3rem" src={image2} />
          <Text  color="black" fontSize="18px" fontWeight="bold" mr="0.35rem">{value}</Text>

          </Flex>
        </Box>
);
}
