import {Box, Button, Image, Text, Flex} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";

export default function SwapTokenSelect2({ value, image }) {

    

    return value !== "" ? (
        <Box
          w="9rem"
          bg="white.50"
          borderRadius="1.25rem"
          boxShadow="rgba(0, 0, 0, 0.075) 0px 6px 10px"
          fontWeight="500"
          color="black">
          <Flex alignItems="center" justifyContent="center">
          <Image boxSize="2rem" alt="Logo" mr="0.35rem" mt="0.3rem" mb="0.3rem" borderRadius="1.25rem" src={image} />
          <Text color="black" fontWeight="bold" >{value}</Text>
          </Flex>
        </Box>
      ) : (
        <Box
          bg="#1e90ff"
          color="white"
          p="0rem 1rem"
          borderRadius="1.25rem"
        >Select token</Box>
      );
}
