import {Box, Button, Image, Text, Flex} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";

export default function TokenSelect({ openTokenModal, value, image }) {
    return value !== "" ? (
        <Button
          w="9rem"
          bg="white.50"
          borderRadius="1.25rem"
          boxShadow="rgba(0, 0, 0, 0.075) 0px 6px 10px"
          fontWeight="500"
          color="black"
          onClick={openTokenModal}
          _hover={{ bg: "rgb(150,224,255)" }}
          rightIcon={<ChevronDownIcon fontSize="1.25rem" cursor="pointer" />}>
          <Flex alignItems="center">
          <Image boxSize="2rem" alt="Logo" mr="0.35rem" borderRadius="1.25rem" src={image} />
          <Text color="black" fontWeight="bold" >{value}</Text>
          </Flex>
        </Button>
      ) : (
        <Button
          bg="#1e90ff"
          color="white"
          p="0rem 1rem"
          borderRadius="1.25rem"
          onClick={openTokenModal}
          _hover={{ bg: "rgb(30,144,255)" }}
          rightIcon={<ChevronDownIcon fontSize="1.25rem" cursor="pointer" />}>Select token</Button>
      );
}
