import {Box, Button, Image, Text, Flex} from "@chakra-ui/react";
import {ChevronDownIcon} from "@chakra-ui/icons";

export default function TokenSelect2({value, image, image2 }) {
    return (
        <Box
          w="9rem"
          bg="white.50"
          borderRadius="1.25rem"
          boxShadow="rgba(0, 0, 0, 0.075) 0px 6px 10px"
          fontWeight="500"
          _hover={{ bg: "white.50" }}
          color="black">
          <Flex alignItems="center" justifyContent="center">
          <Image boxSize="2rem" alt="Logo" mr="0.35rem" borderRadius="1.25rem" src={image} />
          <Text  color="black" fontSize="22px" fontWeight="bold" mr="0.35rem">{value}</Text>
          <Image boxSize="2rem" alt="Logo" mr="0.35rem" borderRadius="1.25rem" src={image2} />
          </Flex>
        </Box>
);
}
