import React, { useContext, useState, useEffect } from "react";
import { Box, Flex, Button, Text, Card, CardHeader, CardBody, CardFooter, Stack, Heading, Divider, StackDivider, useWhyDidYouUpdate, Image, Circle,
         UnorderedList, ListItem, Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { isBrowser, isMobile} from "react-device-detect";         
import { isIOS, isAndroid } from "react-device-detect";         
import { useWeb3 } from "./hooks/useWeb3";
import { usePAN } from "./hooks/usePAN";
import { useIcon } from "./hooks/useIcon";
import { ethers } from "ethers";
import axios from 'axios';
import { AppContext } from "./context";

import logo_pancolor2_2 from "./assets/creampan_color2_2.png";
import logo_wcro from "./assets/WCRO.png";
import logo_cro from "./assets/CRO.png";
import logo_ptcro from "./assets/ptCRO.png";
import logo_ytcro from "./assets/ytCRO.png";
import logo_cronos from "./assets/cronos_light_bg.png";

import pic_mint from "./assets/Mint2.png";
import pic_claim from "./assets/Claim.png";
import pic_utility from "./assets/utility_pool.png"
import pic_burn from "./assets/Burn.png";

import icon_utility from "./assets/service_edit.png";
import icon_value from "./assets/value.png";
import icon_gov from "./assets/governance.png";

import png_twitter from "./assets/twitter.png";
import png_telegram from "./assets/telegram.png";
import png_discord from "./assets/discord.png";
import png_medium from "./assets/medium.png";

function Dashboard() {
  const {
    web3Provider,
    blockNumber,
    color,
    color2
  } = useContext(AppContext);

  const { handleExplorer } = useWeb3();
  const { CircleIcon } = useIcon();
  
  function handleInfoClick() {
    window.open("https://creampan.gitbook.io/creampan-finance/pan/tokenomics", "_blank");
  }

  function handleTwitterClick() {
    window.open("https://twitter.com/CreampanFi", "_blank");
  }

  function handleTelegramClick() {
    window.open("https://t.me/+fYHIdQ3I65djNjQ9", "_blank");
  }  

  function handleDiscordClick() {
    window.open("https://discord.gg/jmN7qMc8PD", "_blank");
  }  

  function handleMediumClick() {
    window.open("https://medium.com/@creampanFi", "_blank");
  }  

  return (
<Box
    align="center"                                 
    display="flex"                                 
    flexDirection="column"                         
    justifyContent="space-between"                 
    height={(isMobile) ? (web3Provider) ? "88.3vh" : "92.3vh"
                       : "92.3vh"}                   
    css={{                                         
      overflowY: "scroll",                  
      "&::-webkit-scrollbar": {                    
        width: (isMobile) ? '0px' : '16px',                             
      },                                           
      "&::-webkit-scrollbar-track": {              
        backgroundColor: "transparent",            
      },                                           
      "&::-webkit-scrollbar-thumb": {              
        backgroundColor: "rgba(205, 102, 0, 0.5)", 
        borderRadius: "20px",                      
        border: "4px solid transparent",           
        backgroundClip: "content-box",             
      },                                           
      "&::-webkit-scrollbar-thumb:hover": {        
        backgroundColor: "rgba(205, 102, 0, 0.8)", 
      },                                           
    }}                                             
  >
  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Flex>
    <Text mt="10rem" fontSize={(isMobile) ? '26px' : '50px'} fontWeight="bold" fontFamily="Arial" >
      Fully Unlock&nbsp;
    </Text>
    <Text mt="10rem" fontSize={(isMobile) ? '26px' : '50px'} fontWeight="bold" fontFamily="Arial" >
      Staking Power
    </Text>
    </Flex>
  </Flex>
  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Text mt="0.5rem" fontSize={(isMobile) ? '25px' : '45px'} fontWeight="bold" fontFamily="Arial" color="#551a8b">
      Stake, Use, and Unstake
    </Text>
  </Flex>
  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Text mt="0.5rem" fontSize={(isMobile) ? '25px' : '45px'} fontWeight="bold" fontFamily="Arial" color="#551a8b">
      Anytime and Anywhere
    </Text>
  </Flex>

  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Text mt="6rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
      Why Creampan?
    </Text>
  </Flex>

  <Flex
      mt="2rem"
      alignItems="center"
      justifyContent="center">
      <Text fontSize='40px' fontWeight="bold" fontFamily="Arial" color="black" mr={2} >1</Text>
      <Image src={logo_cro} boxSize="50px" height="50px" fit="contain" mr={3}/>
      <Text fontSize='40px' fontWeight="bold" fontFamily="Arial" color="black" mr={3} >=</Text>
      <Text fontSize='40px' fontWeight="bold" fontFamily="Arial" color="black" mr={2} >1</Text>
      <Image src={logo_ptcro} boxSize="50px" height="50px" fit="contain" mr={3}/>
      <Text fontSize='40px' fontWeight="bold" fontFamily="Arial" color="black" mr={3} >+</Text>
      <Text fontSize='40px' fontWeight="bold" fontFamily="Arial" color="black" mr={2} >1</Text>
      <Image src={logo_ytcro} boxSize="50px" height="50px" fit="contain" mr={3}/>
  </Flex>


  <Flex
      mt="1.5rem"
      alignItems="center"
      justifyContent="space-evenly">
    <Box mt="2rem" borderRadius="1.8rem" bg="#551a8b" p="0.4rem" color="#551a8b" border="2px" w="2.5vw"></Box>
  </Flex>

  <Flex
      mt="2rem"
      alignItems="center"
      justifyContent="space-evenly"
      flexDirection={(isMobile) ? "column" : "row"}>
    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#551a8b" border="2px" w={(isMobile) ? "97%" : "23%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
      <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >Principle Token CRO (ptCRO)</Text>
      <Image src={logo_ptcro} boxSize="40px" height="40px" fit="contain"/>
      </Flex>
      <UnorderedList>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Staked CRO without yields</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Constant ratio of ptCRO to CRO over time</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Earn DeFi yields without IL</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Earn PAN by staking ptCRO</ListItem>
      </UnorderedList>      
    </Box>
    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#551a8b" border="2px" w={(isMobile) ? "97%" : "23%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
        <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >Yield Token CRO (ytCRO)</Text>
        <Image src={logo_ytcro} boxSize="40px" height="40px" fit="contain"/>
      </Flex>      
      <UnorderedList>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Receive the yield from staked CRO</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Constant ratio of ytCRO to CRO over time</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Earn DeFi yields</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Earn PAN by providing liquidity </ListItem>
      </UnorderedList>      
    </Box>

    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#551a8b" border="2px" w={(isMobile) ? "97%" : "23%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
        <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >Flexible Application</Text>
      </Flex> 
      <UnorderedList>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Earn DeFi yields and PAN rewards</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">No coin lockup and no unstake time</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Risk off price of CRO by selling ptCRO</ListItem>
        <ListItem fontSize={(isMobile) ? '16px' : '18px'} fontWeight="bold">Lend CRO with ptCRO and hold ytCRO</ListItem>
      </UnorderedList>      
    </Box>

  </Flex>

  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
      How Creampan works
    </Text>

  </Flex>
  
  <Flex
      mt="4rem"
      alignItems="center"
      justifyContent="space-evenly">

    <Tabs variant='soft-rounded' borderRadius="0.8rem" bg="transparent" w='90rem'>
      <Flex alignItems="center" justifyContent="space-evenly">
        <TabList>
          <Tab w={(isMobile) ? '4rem' : '5.5rem'} fontSize={(isMobile) ? '15px' : '19px'} fontWeight="bold" fontFamily="Arial" color="#551a8b" border="2px" borderColor="orange" mr="1rem" 
               _selected={{color: "#FFFFFF", bg: "orange", borderColor: "white"}}>Mint</Tab>
          <Tab w={(isMobile) ? '4rem' : '5.5rem'} fontSize={(isMobile) ? '15px' : '19px'} fontWeight="bold" fontFamily="Arial" color="#551a8b" border="2px" borderColor="orange" mr="1rem" 
               _selected={{color: "#FFFFFF", bg: "orange", borderColor: "white"}}>Claim</Tab>
          <Tab w={(isMobile) ? '4rem' : '5.5rem'} fontSize={(isMobile) ? '15px' : '19px'} fontWeight="bold" fontFamily="Arial" color="#551a8b" border="2px" borderColor="orange" mr="1rem" 
               _selected={{color: "#FFFFFF", bg: "orange", borderColor: "white"}}>Utilize</Tab>
          <Tab w={(isMobile) ? '4rem' : '5.5rem'} fontSize={(isMobile) ? '15px' : '19px'} fontWeight="bold" fontFamily="Arial" color="#551a8b" border="2px" borderColor="orange"           
               _selected={{color: "#FFFFFF", bg: "orange", borderColor: "white"}}>Burn</Tab>
        </TabList>
      </Flex>

      <TabPanels mt="1rem">

        <TabPanel>
          <Box w="100%">

            <Flex alignItems="center" justifyContent="space-evenly" flexDirection={(isMobile) ? "column" : "row"}>
              <Image src={pic_mint} fit="contain" borderRadius="0rem" w="550px"/>  
              <Text fontSize={(isMobile) ? '18px' : '22px'} fontFamily="Arial" fontWeight="bold" color="black" mr={2} >Mint ptCRO and ytCRO with CRO or WCRO</Text>
            </Flex>
          </Box>
        </TabPanel>

        <TabPanel>

          <Box w="100%">
            <Flex alignItems="center" justifyContent="space-evenly" flexDirection={(isMobile) ? "column" : "row"}>
              <Image src={pic_claim} fit="contain" borderRadius="0rem" w="700px"/>
              <Text fontSize={(isMobile) ? '18px' : '22px'} fontFamily="Arial" fontWeight="bold" color="black" mr={2} >Claim CRO or WCRO rewards daily</Text>
            </Flex>
          </Box>

        </TabPanel>

        <TabPanel>

          <Box w="100%">
            <Flex alignItems="center" justifyContent="space-evenly" flexDirection={(isMobile) ? "column" : "row"}>
              <Image src={pic_utility} fit="contain" borderRadius="0rem" w="850px"/>
              <UnorderedList>
                <ListItem fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial">Earn DeFi yields with ptCRO and ytCRO</ListItem>
                <ListItem fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial">Sell/Buy ptCRO and ytCRO</ListItem>
                <ListItem fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial">Stake ptCRO to earn PAN</ListItem>
                <ListItem fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial">Lend CRO with ptCRO</ListItem>
              </UnorderedList>      
            </Flex>
          </Box>

        </TabPanel>
        
        <TabPanel>

          <Box w="100%">
            <Flex alignItems="center" justifyContent="space-evenly" flexDirection={(isMobile) ? "column" : "row"}>
              <Image src={pic_burn} fit="contain" borderRadius="0rem" w="500px"/>
              <Box>
              <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >No 28 days unstake period anymore</Text>
              <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >Unstake CRO or WCRO by burning ptCRO and ytCRO</Text>
              <Text fontSize={(isMobile) ? '18px' : '22px'} fontWeight="bold" fontFamily="Arial" color="black" mr={2} >Burn fee will be applied according to pool reserve ratio</Text>
              </Box>
            </Flex>
          </Box>

        </TabPanel>

      </TabPanels>
    </Tabs>

    </Flex>

  <Flex
      alignItems="center"
      justifyContent="space-evenly"
      direction="column"
      >
    <Flex>
      <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
        Protocol token&nbsp;
      </Text>
      <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial" color="#0000ff">
        PAN
      </Text>
    </Flex>
    <Circle mt="2rem" size="120px">
      <Image src={logo_pancolor2_2} fit="contain" borderRadius="5rem"/>  
    </Circle>
    
    <Flex>
      <a href="https://example.com" target="_blank" rel="noopener noreferrer">
        <Button
          mt="2rem"
          color="blue"
          border="2px"
          bg="transparent"
          width="100%"
          p="1.3rem"
          borderColor="orange"
          borderRadius="1.5rem"
          _hover={{ color: "white", bg: "orange", borderColor: "white" }}
          onClick={handleInfoClick}
        >
          PAN Tokenomics
        </Button>
      </a>
    </Flex>

  </Flex>

  <Flex
      mt="2rem"
      alignItems="center"
      justifyContent="space-evenly"
      flexDirection={(isMobile) ? "column" : "row"}>
    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#0048ba" border="2px" w={(isMobile) ? "90%" : "22%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
      <Image src={icon_utility} boxSize="50px" height="50px" fit="contain" mr={2}/>
      <Text fontSize='24px' fontWeight="bold" fontFamily="Arial" color="#0000ff">UTILITY</Text>
      </Flex>
      <UnorderedList>
        <ListItem fontSize='18px' fontWeight="bold">Burn Fee Discount</ListItem>
      </UnorderedList>      
    </Box>
    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#0048ba" border="2px" w={(isMobile) ? "90%" : "22%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
        <Image src={icon_value} boxSize="50px" height="50px" fit="contain" mr={2}/>
        <Text fontSize='24px' fontWeight="bold" fontFamily="Arial" color="#0000ff" >VALUE</Text>
      </Flex>      
      <UnorderedList>
        <ListItem fontSize='18px' fontWeight="bold">Stake for Rewards and Revenue Share</ListItem>
      </UnorderedList>      
    </Box>

    <Box mt="1rem" borderRadius="1.8rem" bg="transparent" p="0.4rem" color="#0048ba" border="2px" w={(isMobile) ? "90%" : "22%" } display="flex" flexDirection="column" alignItems="center">
      <Flex alignItems="center">
      <Image src={icon_gov} boxSize="50px" height="50px" fit="contain" mr={2}/>
        <Text fontSize='22px' fontWeight="bold" fontFamily="Arial" color="#0000ff" >GOVERNANCE</Text>
      </Flex> 
      <UnorderedList>
        <ListItem fontSize='18px' fontWeight="bold">Hold to Vote</ListItem>

      </UnorderedList>      
    </Box>

  </Flex>

  <Flex
      alignItems="center"
      justifyContent="space-evenly">
    <Text mt="10rem" fontSize='35px' fontWeight="bold" fontFamily="Arial">
    </Text>
  </Flex>

  <Flex justify="space-between" alignItems="center" mb="2rem">

    <Flex mr="16px" w="100px">
    </Flex>
    
    <Flex w="135px">
      <Image src={png_twitter} boxSize="25px" height="25px" fit="contain" mr={2} transition="transform .2s" 
             _hover={{ cursor: "pointer", transform: "scale(1.2)", }} onClick={handleTwitterClick} />
      <Image src={png_telegram} boxSize="25px" height="25px" fit="contain" mr={2} transition="transform .2s" 
             _hover={{ cursor: "pointer", transform: "scale(1.2)", }} onClick={handleTelegramClick} />
      <Image src={png_discord} boxSize="25px" height="25px" fit="contain" mr={2} transition="transform .2s" 
             _hover={{ cursor: "pointer", transform: "scale(1.2)", }} onClick={handleDiscordClick} />
      <Image src={png_medium} boxSize="31px" height="25px" fit="contain" transition="transform .2s" 
             _hover={{ cursor: "pointer", transform: "scale(1.2)", }} onClick={handleMediumClick} />
    </Flex>
    
    <Flex mr="0px" w="87px">
    </Flex>
    
  </Flex>

  <Flex bottom="0px" justify="space-between" alignItems="center" width="99vw" mb={(isAndroid) ? "0.7rem" : "0.2rem"}>

    <Flex mr="16px" w="90px">
    </Flex>

    <Flex alignItems="center">
      <Text fontSize={(isMobile) ? '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="white" mr="0.4rem" >powered by</Text>
      <Box width="85px" height="30px">
        <Image src={logo_cronos} fit="contain"/>
      </Box>
    </Flex>

    <Flex alignItems="center" w={(web3Provider) ? "" : "90px"}>
      {web3Provider && (
      <Flex alignItems="center">
      <Button h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color={color2} mr="0.3rem" p="0" onClick={() => handleExplorer( ("/block/" + blockNumber) )}>{blockNumber}</Button>
      <CircleIcon boxSize={3} color={color} mr="0px"/>
      </Flex>
      )}
      {!web3Provider && (
      <Flex alignItems="center">
      <Text h="0px" bg="transparent" fontSize={(isMobile) ? (isAndroid)? '11px' : '13px' : '15px'} fontWeight="bold" fontFamily="Arial" color="transparent" mr="0.3rem" p="0">{blockNumber}</Text>
      <CircleIcon boxSize={(isMobile) ? '2' : '3'} color="transparent" mr="0"/>
      </Flex>
      )}
    </Flex>
  </Flex>

</Box>
  );
}

export default Dashboard;