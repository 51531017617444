import { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import { ChainId, Token, Fetcher, Route } from '@uniswap/sdk';
import { AppContext } from "../context";
import axios from 'axios';

export function usePAN() {
  const {
    MasterBaker_ADDRESS,
    MasterBaker_ABI,
    ptCRO_ADDRESS,
    ytCRO_ADDRESS,
    WCRO_address,
    PAN_address,
    web3Provider,
    cronos_nodeUrl,
    price_ptCRO,
    price_ytCRO,
    price_PAN,
    setPricePTCRO,
    setPriceYTCRO,
    setPricePAN, 
    selectedTab,
    setSelectedTab,
    setAprCRO,
    setStakedCRO,
    setUnstakingCRO
  } = useContext(AppContext);

  const ChainID = 338;

  const UniswapV2Pair_ABI  = require("../abis/UniswapV2Pair_abi.json");
  const UniswapV2ERC20_ABI = require("../abis/UniswapV2ERC20_abi.json");

  async function getCROAPRFromServer() {  
    const response = await axios.get('https://testnet.creampan.finance/croapr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    setAprCRO(Number(response.data.croPoolStats[0].apr).toFixed(2));
    setStakedCRO(Number(Number(response.data.croPoolStats[1].totalStaked)/1e8).toFixed(3));
    setUnstakingCRO(Number(Number(response.data.croPoolStats[1].totalUnstaked)/1e8).toFixed(3));

    //console.log(response.data.croPoolStats);
    return response.data.croPoolStats; 
  }

  async function getAPRFromServer() {  
    const response = await axios.get('https://testnet.creampan.finance/apr');
  
    if (response.status !== 200) {
      throw new Error('Failed to fetch APR');
    }
    //console.log(response.data.poolStats);
    return response.data.poolStats; 
  }

    async function checkAllowance(contractAddress, amount, spender) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider.getSigner());
      const owner = await web3Provider.getSigner().getAddress();

      let allowedAmount;
      try {
        allowedAmount = await ERC20_contract.allowance(owner, spender);

      } catch (error) {
        console.error(error);
      }
      
      let check = Number(ethers.utils.formatEther(allowedAmount)) >= Number(amount);
      //console.log(Number(ethers.utils.formatEther(allowedAmount)).toFixed(1), check);
      return (check);
    }

    async function getTotalSupply(contractAddress) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider.getSigner());
      var totalSupply;
      try {
        totalSupply = await ERC20_contract.totalSupply();
  
      } catch (error) {
        console.error(error);
      }

      return (Number(ethers.utils.formatEther(totalSupply)).toFixed(8));
    }

    async function getBalance(contractAddress, address) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider.getSigner());
      //const currentAddress = await web3Provider.getSigner().getAddress();
      var balance;
      try {
        balance = await ERC20_contract.balanceOf(address);
  
      } catch (error) {
        console.error(error);
      }
      //Number(ethers.utils.formatEther(balance)).toFixed(1)
      
      
      var round = Math.floor(Number(ethers.utils.formatEther(balance))*1000)/1000;

      return (round);
    }

    async function getPTCROPrice() {
      //console.log(ChainId.TESTNET);
      const _token = new Token(ChainID, ptCRO_ADDRESS, 18);
      const WETH   = new Token(ChainID,  WCRO_address, 18);
      //console.log(_token, WETH);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      //console.log(pair);
      const route = new Route([pair], WETH);
      //console.log(route);
      //console.log(Number(route.midPrice.invert().toSignificant(6)));
      setPricePTCRO(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per ptCRO
      //console.log(price_ptCRO);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getYTCROPrice() {
      const _token = new Token(ChainID, ytCRO_ADDRESS, 18);
      const WETH   = new Token(ChainID,  WCRO_address, 18);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      const route = new Route([pair], WETH);
      setPriceYTCRO(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per ytCRO
      //console.log(price_ytCRO);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getPANPrice() {
      //console.log(ChainId.TESTNET);
      const _token = new Token(ChainID,  PAN_address, 18);
      const WETH   = new Token(ChainID, WCRO_address, 18);
      //console.log(_token, WETH);
      const pair = await Fetcher.fetchPairData(_token, WETH, web3Provider);
      //console.log(pair);
      const route = new Route([pair], WETH);
      setPricePAN(Number(route.midPrice.invert().toSignificant(6))); // # of WETH per PAN
      //console.log(price_PAN);
      return (Number(route.midPrice.invert().toSignificant(6)).toFixed(3));
    }

    async function getStakeAmount(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const currentAddress = await web3Provider.getSigner().getAddress();
      const userinfo = await MasterBaker_contract.userInfo(pid, currentAddress); //try catch
      //console.log(pid, Number(ethers.utils.formatEther(userinfo.amount)).toFixed(1));
      return (Number(ethers.utils.formatEther(userinfo.amount)).toFixed(1));
    }

    async function getTotalStake(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const poolinfo = await MasterBaker_contract.poolInfo(pid); //try catch
      const lpToken_contract = new ethers.Contract(poolinfo.lpToken, UniswapV2Pair_ABI, web3Provider);
      var   totalStake = await lpToken_contract.balanceOf(MasterBaker_ADDRESS);

      return (Number(ethers.utils.formatEther(totalStake)).toFixed(1));
    }

    async function getRewards(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider);
      const currentAddress = await web3Provider.getSigner().getAddress();
      const pendingPan = await MasterBaker_contract.pendingPan(pid, currentAddress); //try catch
      return (Number(ethers.utils.formatEther(pendingPan)).toFixed(1));      
    }

    async function claimRewards(pid) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider.getSigner());
      try {
        let tx;
        if (Number(pid) !== 0)
          tx = await MasterBaker_contract.deposit(pid, '0');
        else
          tx = await MasterBaker_contract.enterStaking('0');
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
    }

    async function depositToken(pid, amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider.getSigner());
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var stakeAmount = ethers.utils.parseEther(amount);
      }
      //console.log(pid, stakeAmount);
      try {
        const tx = await MasterBaker_contract.deposit(pid, stakeAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
    }

    async function withdrawToken(pid, amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider.getSigner());
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var withdrawAmount = ethers.utils.parseEther(amount);
      }
      try {
        const tx = await MasterBaker_contract.withdraw(pid, withdrawAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
    }

    async function enterStakeToken(amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider.getSigner());
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var stakeAmount = ethers.utils.parseEther(amount);
        //console.log(Number(stakeAmount));
      }
      try {
        const tx = await MasterBaker_contract.enterStaking(stakeAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
    }

    async function leaveStakeToken(amount) {
      const MasterBaker_contract = new ethers.Contract(MasterBaker_ADDRESS, MasterBaker_ABI, web3Provider.getSigner());
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var withdrawAmount = ethers.utils.parseEther(amount);
      }
      try {
        const tx = await MasterBaker_contract.leaveStaking(withdrawAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
    }

    async function approveToken(contractAddress, spender, amount) {
      const ERC20_contract = new ethers.Contract(contractAddress, UniswapV2ERC20_ABI, web3Provider.getSigner());
      var tx;
      if (/^\d+(\.\d+)?$/.test(amount)) {
        var approveAmount = ethers.utils.parseEther(amount);
      }
      try {
        tx = await ERC20_contract.approve(spender, approveAmount);
        //console.log(tx);
  
      } catch (error) {
        console.error(error);
      }
      return (tx);
    }

  return {
    checkAllowance,
    getCROAPRFromServer,
    getAPRFromServer,
    getTotalSupply,
    getBalance,
    getPTCROPrice,
    getYTCROPrice,
    getPANPrice,
    getStakeAmount,
    getTotalStake,
    getRewards,
    claimRewards,
    depositToken,
    withdrawToken,
    enterStakeToken,
    leaveStakeToken,
    approveToken
  };
}
