import {
    Box,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Table, Thead, Tbody, Tr, Th, Td, TableCaption, TableContainer,
    Flex,
    Image,
    Text
  } from "@chakra-ui/react";
  import Axios from "axios";
  import { useEffect, useState, useContext } from "react";

  import logo_cro from "../assets/CRO.png";  
  import logo_wcro from "../assets/WCRO.png";

  import { AppContext } from "../context";


  export default function TokenModal({isOpen, onClose}) {
    const [search, setSearch] = useState("");
    const {
      selectedCoin,
      setSelectedCoin,
    } = useContext(AppContext);

    const coinList = [
      {
        name: "Cronos",
        symbol: "CRO",
        icon: logo_cro,
      },
      {
        name: "Wrapped CRO",
        symbol: "WCRO",
        icon: logo_wcro,
      },
    ];

    return (
      <Modal isOpen={isOpen} onClose={onClose} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          background="orange.100"
          border="0.2rem"
          borderStyle="solid"
          borderColor="orange.300"
          borderRadius="3xl">
          <ModalHeader color="black" px={4} fontSize="lg" fontWeight="bold">
            Select a token
          </ModalHeader>
          <ModalCloseButton
            color="black"
            fontSize="sm"
            _hover={{
              color: "gray.600",
            }}
            onClick={(e) => onClose()}
            />
          <ModalBody pt={0} px={4}>
          <Box
            borderRadius="1.25rem"
            border="0.06rem"
            borderStyle="solid"
            borderColor="gray.200"
            bg="rgb(255, 255, 245)"
            p="0.8em">
            <Input
              placeholder="Search token name"
              fontWeight="500"
              fontSize="1.3rem"
              width="100%"
              size="19rem"
              textAlign="left"
              outline="none"
              border="none"
              focusBorderColor="none"
              borderRadius="0.6rem"
              type="text"
              color="black"
              p="0.3rem"
              style={{ boxShadow: "none" }}
              value={search}
              onChange={(e) => {
                setSearch(e.target.value.replace(/[^a-zA-Z0-9]/g, ""));
              }}
              />
          </Box>

            <div>
              <Table variant='unstyle' mt="0.3rem">
                <Tbody>
                  {coinList
                    .filter((val) => {
                      return val.name.toLowerCase().includes(search.toLowerCase());
                    })
                    .map((val) => {
                      return (
                          <Tr key={val.name}
                              style={{ backgroundColor: (val.name === selectedCoin.name ? "#F7B45D" :"")}}
                              _hover={{backgroundColor: "orange.200"}}
                              onClick={function (e) {
                                setSelectedCoin({name: val.name, symbol: val.symbol, icon: val.icon});
                                onClose();  
                              }}>
                            <Td>
                                <Flex alignItems="center" justifyContent="left">
                                <Image src={val.icon} alt="logo" width="40px" borderRadius="1.25rem" mr="1rem"/>
                                <Box>
                                <Text fontSize="20px" fontWeight="bold" mb="0.3rem">{val.symbol}</Text>
                                <Text fontSize="16px" color="#848482" fontWeight="bold">{val.name}</Text>
                                </Box>
                                </Flex>
                            </Td>
                        </Tr>
                      );
                    })
                  }
                </Tbody>
              </Table>
            </div>
          </ModalBody>
  
          <ModalFooter
            justifyContent="flex-start"
            background="orange.200"
            borderBottomLeftRadius="3xl"
            borderBottomRightRadius="3xl"
            p="1rem">
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
  