import { Box } from "@chakra-ui/react";

const CustomSwitch = ({ checkedBackground, uncheckedBackground, isChecked, toggleSwitch}) => {

  return (
    <Box
      width="30px"
      height="16px"
      borderRadius="10px"
      bg={isChecked ? checkedBackground : uncheckedBackground}
      position="relative"
      onClick={toggleSwitch}
      cursor="pointer"
    >
      <Box
        position="absolute"
        top="2px"
        left={isChecked ? "16px" : "2px"}
        transition="left 0.2s"
        width="12px"
        height="12px"
        borderRadius="50%"
        bg="white"
      />
    </Box>
  );
};

export default CustomSwitch;
